import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { sections } from './header';

export function Header() {
  const classes = useStyles();

  const handleNavigate = (section: any) => {
    let element = section.ref.current;

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: element.offsetTop,
    });
  };

  return (
    <Container maxWidth="lg" className={classes.title}>
      {sections.map((section) => (
        <Button key={section.name} onClick={() => handleNavigate(section)}>
          {section.name}
        </Button>
      ))}
    </Container>
  );
}

const useStyles = makeStyles({
  title: {
    textAlign: 'right',
    paddingTop: 15,
    paddingBottom: 15,
  },
});
