/* eslint-disable react/jsx-no-target-blank */
import React, { forwardRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Container, Dialog } from '@material-ui/core';
import { portfolio, PortfolioType } from './potfolio';

export const Portfolio = forwardRef((_props, ref: any) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<PortfolioType>();

  return (
    <div ref={ref}>
      <Container maxWidth="lg" className={classes.root}>
        <h1 className={classes.textLeft}>Portfolio</h1>
        <Grid container spacing={3} className={classes.textLeft}>
          {portfolio.map((project) => (
            <Grid item md={3} sm={6} xs={6}>
              <div
                className={classes.portfolioBox}
                onClick={() => {
                  setIsModalOpen(true);
                  setSelectedProject(project);
                }}
              >
                <div className={classes.portfolioImage}>
                  <img
                    className={classes.image}
                    src={project.images[0]}
                    alt="logo"
                  />
                </div>
                <div className={classes.portfolioTitle}>{project.title}</div>
                <div className={classes.date}>{project.date}</div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {selectedProject && (
          <div className={classes.modal}>
            <div className={classes.modalTitle}>{selectedProject.title}</div>
            <div className={classes.modalDescription}>
              {selectedProject.description}
            </div>
            {selectedProject?.website && (
              <p className={classes.modalP}>
                <span className={classes.bullet}>WEBSITE</span>
                <a
                  className={classes.detail}
                  target='_blank'
                  href={selectedProject.website}
                >
                  www.latterdaylearning.app
                </a>
              </p>
            )}
            <p className={classes.modalP}>
              <span className={classes.bullet}>STACK</span>
              <span className={classes.detail}>{selectedProject.stack}</span>
            </p>
            <p className={classes.modalP}>
              <span className={classes.bullet}>DATE</span>
              <span className={classes.detail}>{selectedProject.date}</span>
            </p>
            {selectedProject.images.slice(1).map((image) => (
              <img className={classes.modalImage} src={image} alt="Logo" />
            ))}
          </div>
        )}
      </Dialog>
    </div>
  );
});

const useStyles = makeStyles({
  root: {
    padding: '15px 40px 0px',
  },
  bullet: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 1.66,
    float: 'left',
    display: 'block',
  },
  container: {
    paddingTop: 15,
  },
  detail: {
    paddingLeft: 90,
    display: 'block',
    opacity: 0.9,
    fontSize: 14,
  },
  textLeft: {
    textAlign: 'left',
  },
  date: {
    opacity: 0.8,
  },
  image: {
    margin: 0,
    width: '100%',
    height: 'auto',
    verticalAlign: 'middle',
    border: 0,
  },
  modal: {
    padding: '20px 30px',
    backgroundColor: '#f3f3f3',
  },
  modalImage: {
    width: '42%',
    padding: 10,
  },
  modalTitle: {
    fontSize: 36,
    lineHeight: 1.25,
    fontWeight: 500,
  },
  modalDescription: {
    marginBottom: 20,
    opacity: 0.9,
  },
  modalP: {
    paddingBottom: 7,
  },
  smallDivider: {
    marginTop: 30,
    marginBottom: -15,
  },
  portfolioBox: {
    display: 'block',
    margin: '0 auto 30px',
    textAlign: 'left',
    position: 'relative',
    overflow: 'hidden',
    height: 'auto',
    cursor: 'pointer'
  },
  portfolioImage: {
    marginBottom: 10,
    overflow: 'hidden',
    borderRadius: 2,
  },
  portfolioTitle: {
    lineHeight: 1.55,
    fontWeight: 500,
    fontSize: 18,
  },
});
