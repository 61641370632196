import { BackgroundType } from './types';

export const educationInformation: BackgroundType[] = [
  {
    title: 'B.S, Software Engineering',
    organization: 'Utah Valley University',
    dateRange: 'Dec 2019',
    description:
    'Capstone project: Built a front-facing loyalty program application for ForeUP using Vue.js. The program would be used by clients at 900 golf courses across the country • Courses in: Mobile Development, Web Programming, Data Structures, Object Oriented Pro-gramming, Advanced C# Development, Advanced Java Development',
  },
];

export const educationTitle = 'Education';
export const educationDescription = `Driven by the conviction that learning is important throughout one's life and career.`;
