import React from 'react';

export const sections = [
  {
    name: 'About Me',
    ref: React.createRef(),
  },
  {
    name: 'Skills',
    ref: React.createRef(),
  },
  {
    name: 'Experience',
    ref: React.createRef(),
  },
  {
    name: 'Education',
    ref: React.createRef(),
  },
  {
    name: 'Portfolio',
    ref: React.createRef(),
  },
];
