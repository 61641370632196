import React from 'react';
import { HomePage } from './Home.component';
import 'fontsource-roboto';
import './App.css';

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
