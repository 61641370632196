import teslaclip1 from './TeslaClips/TeslaClips6.png';
import teslaclip2 from './TeslaClips/TeslaClips.png';
import teslaclip3 from './TeslaClips/TeslaClips2.png';
import teslaclip4 from './TeslaClips/TeslaClips3.png';
import teslaclip5 from './TeslaClips/TeslaClips5.png';
import teslaclip6 from './TeslaClips/TeslaClips4.png';
import thingstodo1 from './ThingsToDoUtah/ThingstoDoUtah3.png';
import thingstodo2 from './ThingsToDoUtah/ThingsToDoUtah1.png';
import thingstodo3 from './ThingsToDoUtah/ThingsToDoUtah2.png';
import thingstodo4 from './ThingsToDoUtah/ThingsToDoUtah.png';
import blocklander1 from './BlockLander/BlockLander2.png';
import blocklander2 from './BlockLander/BlockLander.png';
import logo from './LatterDayLearning/logo.png'

export interface PortfolioType {
  title: string;
  description: string;
  website?: string;
  stack: string;
  date: string;
  images: string[];
}

export const portfolio: PortfolioType[] = [
  {
    title: 'Latter Day Learning',
    description: 'A website I developed that uses Ai to help with personal study or lesson planning.',
    website: 'https://www.latterdaylearning.app',
    stack: 'Next.js, Postgres, Supabase, Stripe, Gemini',
    date: 'June 2024 - Present',
    images: [logo]
  },
  {
    title: 'Things To Do Utah',
    description:
      'Lifestyle app to help users find recreational events and activities in the Utah Valley area.',
    stack: 'Swift, UIKit, MapKit',
    date: 'December 2017 - October 2019',
    images: [thingstodo1, thingstodo2, thingstodo3, thingstodo4],
  },
  {
    title: 'Tesla Clips',
    description:
      'Media player to view, organize, save, and discard footage recorded from Tesla car cameras.',
    stack: 'Swift, CloudKit, SwiftUI, AVFoundation',
    date: 'October 2019 - April 2020',
    images: [
      teslaclip1,
      teslaclip2,
      teslaclip3,
      teslaclip4,
      teslaclip5,
      teslaclip6,
    ],
  },
  {
    title: 'Block Lander',
    description:
      'Game in which player attempts to land a small square on a platform as difficulty increases.',
    stack: 'Swift and SpriteKit',
    date: 'May 2018 - July 2018',
    images: [blocklander1, blocklander2],
  }
];
