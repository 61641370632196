import React from 'react';
import { Header } from './Header.component';
import Divider from '@material-ui/core/Divider';
import { AboutMe } from './AboutMe.component';
import { Skills } from './Skills.component';
import { makeStyles } from '@material-ui/core';
import { Background } from './background.component';
import { workExperience, workDescription, workTitle } from './experience';
import {
  educationInformation,
  educationTitle,
  educationDescription,
} from './education';
import { sections } from './header';
import { Portfolio } from './portfolio.component';

export function HomePage() {
  const classes = useStyles();
  return (
    <>
      <Header />
      <Divider />
      <AboutMe ref={sections[0].ref} />
      <Divider className={classes.divider} />
      <Skills ref={sections[1].ref} />
      <Divider className={classes.divider} />
      <Background
        ref={sections[2].ref}
        title={workTitle}
        description={workDescription}
        backgroundInformation={workExperience}
      />
      <Divider className={classes.divider} />
      <Background
        ref={sections[3].ref}
        title={educationTitle}
        description={educationDescription}
        backgroundInformation={educationInformation}
      />
      <Divider className={classes.divider} />
      <Portfolio ref={sections[4].ref} />
    </>
  );
}

const useStyles = makeStyles({
  divider: {
    marginTop: 30,
  },
});
