import { BackgroundType } from './types';

export const workExperience: BackgroundType[] = [
  {
    title: 'Senior Software Engineer',
    organization: 'Bill (Formerly Divvy - Acquired by Bill)',
    dateRange: 'April 2022 - Present',
    description:
      'Team lead • Contribute and build tools to enable the engineers on my team • Help frontend organization with best practices for GraphQL • Build out backend features using Elixir • Lead out the redesign of the Payment Portal on backend and frontend • Helped convert from zion bank to chase wallet payment system that processes over 1 billion dollars a month',
  },
  {
    title: 'Software Engineer II',
    organization: 'Bill (Formerly Divvy - Acquired by Bill)',
    dateRange: 'May 2021 - April 2022',
    description:
      'Helped mentor new frontend devs • Led a team of 3 other frontend devs • Built out backend features that frontend can consume using Graphql',
  },
  {
    title: 'Software Engineer I',
    organization: 'Bill (Formerly Divvy - Acquired by Bill)',
    dateRange: 'February 2021 - May 2021',
    description:
      'Used React and GraphQL to build out features • Used a design system built on storybook and helped contribute to the library of component packages.',
  },
  {
    title: 'Software Engineer',
    organization: 'DealerSocket, Inc.',
    dateRange: 'July 2019 - February 2021',
    description:
      'Rewrote two legacy applications into React • Mentored and onboarded new developers to the projects • Promoted to tech lead on team • Used component library built internally based off of designs • Used TypeScript',
  },
  {
    title: 'Independent iOS Developer',
    organization: '',
    dateRange: 'Dec 2017 - Present',
    description:
      'Things To Do Utah - 2300 downloads - 5.0 star rating • TeslaClips - Media viewer for Tesla car cameras',
  },
  {
    title: 'Software Engineer',
    organization: 'Hexly, LLC',
    dateRange: 'Jan 2019 - June 2019',
    description:
      'Converted their web applications to native iOS applications in Swift • Contributed significantly to websites for multiple clients using Vue.js • Helped build GraphQL layer for their Serverless backend server.  • Used graphQL on Frontend',
  },
  {
    title: 'Software Engineering Intern',
    organization: 'O.C. Tanner',
    dateRange: 'Feb 2018 - June 2018',
    description:
      'Took initiative to automate SQL scripts, saving DBA an hour of daily work • Helped transition company from Oracle to PostgreSQL • Built internal Java application to automate reports',
  },
  {
    title: 'Data Analyst I/Web Developer',
    organization: 'DealerSocket, Inc.',
    dateRange: 'May 2015 - Feb 2018',
    description:
      'Wrote and executed SQL queries to find and correct inconsistencies in the data • Modified Stored Procedures and triggers • Programmed web forms in HTML, CSS, JavaScript, Visual Basic, C# • Used Salesforce to track cases, requests, defects and communicate with clients',
  },
];

export const workTitle = 'Experience';
export const workDescription = `Demonstrates history of quality work in various environments.  Shows self-starter attitude, passion for programming, and ability to learn new concepts quickly and proficiently.`;
