import React, { forwardRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  Container,
  Divider,
  useMediaQuery,
} from '@material-ui/core';

export const Skills = forwardRef((props, ref: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div ref={ref}>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3} className={classes.textLeft}>
          <Grid item xs={12} sm={4}>
            <div>
              <h1 className={classes.textLeft}>Skills</h1>
              <p>
                Offers both expertise in programming as well as a track record
                as a great team player. Commited to finding the best solution
                and delivering quality code that will last.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <h4>Front End Development</h4>
              <p>HTML5, CSS, React, Next.js, Tailwind, GraphQL, Redux, Vue, JavaScript, JSON, Emotion</p>
              <br />
              <Divider className={classes.divider} />
              <h4>iOS Development</h4>
              <p>
                Swift, UIKit, MVC, MVVM, SwiftUI, GraphQL, Apollo, Firebase,
                CoreData, AVFoundation
              </p>
              <Divider className={classes.divider} />
              <h4>Backend Development</h4>
              <p>
                Postgres, Elixir, Node, GraphQL, SQL, Serverless, Stripe, Webhooks
              </p>
              {smallScreen && <Divider className={classes.smallDivider} />}
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <h4>Core Programming Concepts</h4>
              <p>
                Strong understanding of client/server model, RESTful API’s, and
                reactive frameworks; Writes clean, reusable code that is easy to
                understand
              </p>
              <Divider className={classes.divider} />
              <h4>Work Ethic</h4>
              <p>
                Reliable and focused on completing commited tasks in good time
                and quality; Strengthens team by offering and seeking
                collaborative solutions to problems; Researches new technologies
                on own time
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
});

const useStyles = makeStyles({
  root: {
    padding: '15px 40px 0px',
  },
  textLeft: {
    textAlign: 'left',
  },
  divider: {
    marginTop: 30,
    marginBottom: 15,
  },
  smallDivider: {
    marginTop: 30,
    marginBottom: -15,
  },
});
