import React, { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Container, Divider } from '@material-ui/core';
import { BackgroundType } from './types';

interface BackgroundProps {
  title: string;
  description: string;
  backgroundInformation: BackgroundType[];
  ref: any;
}

export const Background = forwardRef(
  (
    { title, description, backgroundInformation }: BackgroundProps,
    ref: any
  ) => {
    const classes = useStyles();

    return (
      <div ref={ref}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.textLeft}>
            <Grid item xs={12} sm={4}>
              <div>
                <h1 className={classes.textLeft}>{title}</h1>
                <p>{description}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              {backgroundInformation.map(
                (section: BackgroundType, index: number) => (
                  <div key={`${section.title} ${index}`}>
                    <h4 className={classes.jobTitle}>{section.title}</h4>
                    <p className={classes.company}>{section.organization}</p>
                    <p className={classes.date}>{section.dateRange}</p>
                    <p className={classes.description}>{section.description}</p>

                    {index + 1 !== backgroundInformation.length && (
                      <Divider className={classes.divider} />
                    )}
                  </div>
                )
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
);

const useStyles = makeStyles({
  container: {
    padding: '15px 40px 0px',
    textAlign: 'left',
  },
  company: {
    opacity: 0.8,
    display: 'inline-block',
    marginBlockStart: '1.33em',
  },
  date: {
    opacity: 0.8,
  },
  description: {
    marginTop: 10,
  },
  divider: { marginTop: 20 },
  textLeft: {
    textAlign: 'left',
  },
  jobTitle: {
    marginRight: 10,
    display: 'inline-block',
    marginBottom: 0,
  },
});
