import React, { forwardRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import image from './family-smaller.jpg';
import { Instagram, LinkedIn } from '@material-ui/icons';

export const AboutMe = forwardRef((props, ref: any) => {
  function handleSocialMediaClick(url: string) {
    window.location.href = url;
  }
  const classes = useStyles();

  return (
    <div ref={ref}>
      <Container className={classes.root} maxWidth="lg">
        <Typography className={classes.textLeft} variant="h4">
          Eliott Moreno
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} className={classes.aboutMe}>
            <p>
              Experienced and detail-oriented Fullstack Software Engineer
              with success in side projects, startups, and established
              corporations. Well-versed in reactive programming with strong
              working knowledge of RESTful APIs and data structures. Highly
              skilled in team collaboration to produce robust, reusable, and
              readable code. Knowledgable in implementing and using GraphQL on frontend. 
            </p>
            <p>
              <span className={classes.bullet}>PHONE</span>
              <span className={classes.detail}>951-977-0765</span>
            </p>
            <p>
              <span className={classes.bullet}>EMAIL</span>
              <span className={classes.detail}>eliott.g.moreno@gmail.com</span>
            </p>
            <p className={classes.marginBottom}>
              <span className={classes.bullet}>SOCIAL</span>
              <span className={classes.detail}>
                <span>
                  <Instagram
                    className={classes.icon}
                    onClick={() => handleSocialMediaClick('https://www.instagram.com/eliottmor/')}
                  />
                  <LinkedIn
                    className={classes.icon}
                    onClick={() => handleSocialMediaClick('https://www.linkedin.com/in/eliott-moreno/')}
                  />
                </span>
              </span>
            </p>
            {/* <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disableElevation
            >
              Contact Me
            </Button>
            <Button
              className={classes.downloadButton}
              variant="contained"
              disableElevation
            >
              Download Resume
            </Button> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <img className={classes.image} src={image} alt="Logo" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
});

const useStyles = makeStyles({
  root: {
    padding: '15px 40px 0px',
    '& p': {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  aboutMe: {
    color: '#333',
    textAlign: 'left',
  },
  bullet: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 1.66,
    float: 'left',
    display: 'block',
  },
  button: {
    backgroundColor: '#1976d2',
    height: 35,
  },
  detail: {
    paddingLeft: 90,
    display: 'block',
    opacity: 0.8,
    fontSize: 14,
  },
  downloadButton: {
    height: 35,
    marginLeft: 15,
  },
  icon: {
    width: 16,
    height: 16,
    paddingRight: 20,
    '&:hover': {
      color: '#1976d2',
      cursor: 'pointer',
    },
  },
  image: {
    width: 360,
    marginTop: -15,
  },
  marginBottom: {
    marginBottom: 30,
  },
  textLeft: {
    textAlign: 'left',
  },
});
